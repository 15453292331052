<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>{{ $t("vatRates.vatRates") }}</h4>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-type-4 btn-small"
          v-on:click="paymentMethodsModal"
        >
          <span>{{ $t("vatRates.paymentMethods") }}</span>
        </button>
      </div>
    </div>
    <div class="line-between-rows">
      <div class="row mb-3" v-for="country in vatRates" :key="country.code">
        <div class="col-12">
          <b>{{ country.name }}</b>
          <OneByOneTable
            v-model:rows="vatRows"
            v-model:items="country.vatRates"
            type="VAT type"
            :empty-line="true"
            :show-del="false"
            :show-add="false"
            v-on:update:field="update"
            v-on:add:field="(e, f) => add(country.id, e, f)"
            v-on:delete="deleteRowModal"
          />
        </div>
      </div>
    </div>
    <PaymentsModal ref="paymentsModal" />
  </div>
</template>

<script>
import http from "@/modules/http";
import OneByOneTable from "@/components/lists/OneByOneTable";
import PaymentsModal from "../../modals/PaymentModal";

export default {
  name: "VatRates",
  components: { PaymentsModal, OneByOneTable },
  data() {
    return {
      filters: {
        country: "",
        language: "",
        currency: "",
      },
      vatRows: [
        {
          name: "ID",
          key: "number",
          type: "id",
          show: false,
        },
        {
          name: "name of VAT type",
          key: "name",
          type: "string",
          show: true,
        },
        {
          name: "Default",
          key: "default",
          type: "boolean",
          show: true,
        },
        {
          name: "Rate",
          key: "rate",
          type: "number",
          postfix: "%",
          show: true,
        },
      ],
      vatRates: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    update(row, key, val) {
      let send = {};
      send[key] = val;
      http.fetch("/vat/" + row.id, send);
    },
    add(country, key, val) {
      let send = {
        country: country,
      };
      send[key] = val;
      http.fetch("/vat", send).then((data) => {
        this.vatRates.find((e) => e.id === country).vatRates.push(data);
      });
    },
    paymentMethodsModal() {
      this.$refs.paymentsModal.showModal();
    },
    load() {
      if (this.vatRates === null) {
        http.fetch("/vat").then((data) => {
          this.vatRates = data;
        });
      }
    },
  },
};
</script>
